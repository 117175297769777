<template>
  <p class="clearfix mb-0" style="opacity: .3">
    <span class="float-md-right d-none d-md-block">&copy; Portstock</span>
  </p>
</template>

<script>
export default {

}
</script>
