export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Products',
    route: 'images',
    icon: 'ImageIcon',
    children: [
      {
        title: 'Manage',
        route: 'manageProducts',
        icon: 'EditIcon',
      },
      {
        title: 'Upload',
        route: 'uploadProducts',
        icon: 'UploadIcon',
      }
    ]
  },
  {
    title: 'Profile',
    route: 'editProfile',
    icon: 'UserIcon',
  },
  {
    title: 'Integrations',
    route: 'integrations',
    icon: 'GridIcon',
  },
]
